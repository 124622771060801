<template>
  <div class="internet-plans">
    <v-row>
      <v-col
        cols="12"
        md="6"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        class="text-md-right"
        cols="12"
        md="3"
      >
        <v-btn
          v-if="can('internet-plan-create')"
          color="primary"
          outlined
          @click="showImportDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiImport }}
          </v-icon>
          Import
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="3"
        class="text-md-right"
      >
        <v-btn
          v-if="can('internet-plan-create')"
          color="primary"
          class="mb-5"
          @click="showAddInternetPlanDialog = true"
        >
          <v-icon
            left
            size="22"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          Add Internet Plan
        </v-btn>
      </v-col>
    </v-row>
    <v-expand-transition>
      <v-card>
        <v-card-title>
          <v-row
            justify="center"
            class="mb-0"
          >
            <v-col
              cols="12"
              md="4"
            >
              <refresh-button
                :loading="isInternetPlansRefreshing"
                @click="refreshInternetPlans()"
              />
            </v-col>
            <v-col
              cols="12"
              md="6"
              class="text-center"
            >
              <search-input
                v-model="search"
                :filters="searchFilters"
                :active-filter="searchColumn"
                @filter-change="onSearchFilterChange"
              />
            </v-col>
            <v-spacer class="d-none d-md-inline" />
          </v-row>
        </v-card-title>
        <v-data-table
          v-model="selected"
          :headers="headers"
          :items="InternetPlans"
          :loading="isInternetPlansLoading"
          :server-items-length="pagination.totalItems"
          :options.sync="options"
          :footer-props="footerProps"
          show-select
        >
          <template v-slot:item.price="{ item }">
            <span v-if="item.price !== 0">
              Ksh {{ item.price | formatCurrency }}
            </span>
            <span v-else-if="isFreeTrialPlan(item.name)">
              <!-- Leave the price field empty for free trial plans -->
            </span>
            <span v-else>
              <v-btn
                small
                outlined
                color="primary"
                @click="addPrice(item)"
              >
                Add price
              </v-btn>
            </span>
          </template>
          <template v-slot:item.validity="{ item }">
            {{ formatValidity(item.validity_unit, item.validity) }}
          </template>
          <template v-slot:item.data_limit="{ item }">
            {{ item.data_limit ? formatDataLimitName(item.data_limit_unit, item.data_limit) : 'None' }}
          </template>
          <template v-slot:item.station="{ item }">
            {{ item.router.station.name }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip
              v-if="can('internet-plan-edit')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  ma-4
                  v-bind="attrs"
                  text
                  icon
                  @click="toggleVisibility(item)"
                  v-on="on"
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ item.is_visible ? icons.mdiEye : icons.mdiEyeOff }}
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ item.is_visible ? 'This internet plan is visible to customers, click to hide it.' : 'This internet plan is hidden from customers, click to unhide it.' }}</span>
            </v-tooltip>
            <v-tooltip
              v-if="can('internet-plan-edit')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  ma-4
                  v-bind="attrs"
                  text
                  icon
                  @click="editInternetPlan(item)"
                  v-on="on"
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Edit</span>
            </v-tooltip>
            <v-tooltip
              v-if="can('internet-plan-delete')"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  ma-4
                  v-bind="attrs"
                  text
                  icon
                  @click="showDeleteInternetPlanDialog(item.id)"
                  v-on="on"
                >
                  <v-icon
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiDelete }}
                  </v-icon>
                </v-btn>
              </template>
              <span>Delete</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card>
    </v-expand-transition>
    <add-internet-plan-dialog
      :show-dialog="showAddInternetPlanDialog"
      :plan="InternetPlan"
      @close="
        showAddInternetPlanDialog = false
        InternetPlan = {}
      "
      @internet-plan-added="onInternetPlanAdded"
      @internet-plan-updated="onInternetPlanUpdated"
    />
    <confirm-dialog
      :show-dialog="showConfirmDeleteDialog"
      :is-agree-button-loading="isConfirmDeleteDialogButtonLoading"
      :agree-text="'Delete'"
      @cancel="showConfirmDeleteDialog = false"
      @agree="deleteInternetPlan()"
    />
    <add-internet-plan-price-dialog
      :show-dialog="showAddInternetPlanPriceDialog"
      :internet-plan="InternetPlan"
      @close="
        showAddInternetPlanPriceDialog = false
        InternetPlan = {}
      "
      @internet-plan-price-added="refreshInternetPlans"
    />
    <import-from-station-or-router-dialog
      v-model="showImportDialog"
      title="Import Internet Plans"
      url="/import/internet-plans/pppoe"
      @close="showImportDialog = false"
      @imported="refreshInternetPlans"
    />
  </div>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import {
  mdiMagnify, mdiPencil, mdiDelete, mdiPlus, mdiImport, mdiEye, mdiEyeOff,
} from '@mdi/js'
import AddInternetPlanDialog from '@/components/dialogs/AddInternetPlanDialog.vue'
import ConfirmDialog from '@/components/dialogs/ConfirmDialog.vue'
import AddInternetPlanPriceDialog from '@/components/dialogs/AddInternetPlanPriceDialog.vue'
import ImportFromStationOrRouterDialog from '@/components/dialogs/ImportFromStationOrRouterDialog.vue'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import billingIntervalName from '@/mixins/billingIntervalName'
import internetConnectionTypeEnum from '@/enums/internetConnectionTypeEnum'
import hasPermission from '@/mixins/hasPermission'

export default {
  components: {
    AddInternetPlanDialog,
    ConfirmDialog,
    AddInternetPlanPriceDialog,
    ImportFromStationOrRouterDialog,
    breadcrumb: BreadCrumb,
    SearchInput,
    RefreshButton,
  },
  mixins: [billingIntervalName, internetConnectionTypeEnum, hasPermission],
  data() {
    return {
      internetConnectionType: parseInt(this.$route.params.internet_connection_type, 10),
      showAddInternetPlanDialog: false,
      showConfirmDeleteDialog: false,
      showImportDialog: false,
      showAddInternetPlanPriceDialog: false,
      isConfirmDeleteDialogButtonLoading: false,
      isInternetPlansRefreshing: false,
      toDelete: null,
      sortBy: ['name'],
      sortDesc: [true],
      search: '',
      pagination: {
        page: 1,
        pageCount: 1,
        itemsPerPage: 10,
        totalItems: 0,
      },
      footerProps: {
        itemsPerPageOptions: [5, 10, 25, 50, 100],
      },
      selected: [],
      options: {},
      searchColumn: 'name',
      searchFilters: [
        {
          text: 'Name',
          value: 'name',
        },
      ],
      icons: {
        mdiMagnify,
        mdiPencil,
        mdiDelete,
        mdiPlus,
        mdiImport,
        mdiEye,
        mdiEyeOff,
      },
      InternetPlan: {},
      InternetPlans: [],
      isInternetPlansLoading: true,
      InternetPlansUrl: 'internet-plans',
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: { name: 'dashboard' },
        },
        {
          text: 'Internet Plans',
          disabled: true,
          to: { name: 'internet-plans' },
        },
      ],
    }
  },
  computed: {
    refreshTable() {
      return `${this.selectedStation}|  ${this.InternetPlansUrl}|${this.search}|${this.sortBy}|${this.sortDesc}`
    },
    ...mapGetters(['selectedStation']),
    headers() {
      const headers = [
        {
          text: 'Name',
          value: 'name',
        },
        {
          text: 'Price',
          value: 'price',
        },
        {
          text: 'Validity',
          value: 'validity',
        },
        { text: 'Actions', value: 'actions', sortable: false },
      ]

      if (this.selectedStation === 'all') {
        headers.splice(headers.length - 1, 0, { text: 'Station', value: 'station' })
      }

      return headers
    },
  },
  watch: {
    options: {
      handler() {
        this.getInternetPlans()
      },
      deep: true,
    },
    refreshTable() {
      this.getInternetPlans()
    },
    searchColumn() {
      if (this.search !== '') {
        this.getInternetPlans()
      }
    },
  },
  mounted() {
    if (!this.can('internet-plan-edit') && !this.can('internet-plan-delete')) {
      this.removeActionsHeader()
    }
    this.getInternetPlans()
    this.breadcrumbs.push({
      text: this.getEnumKeyByValue(internetConnectionTypeEnum, this.internetConnectionType),
      disabled: false,
      to: { name: 'internet-plans', params: { internet_connection_type: this.internetConnectionType } },
    })
  },
  methods: {
    getInternetPlans: _.debounce(function () {
      this.isInternetPlansLoading = true
      const {
        sortBy, sortDesc, page, itemsPerPage,
      } = this.options
      const sortOrder = sortDesc[0] ? 'desc' : 'asc'

      axios
        .get(
          `${this.InternetPlansUrl}?paginate=true&sortBy=${sortBy[0]}&sortDirection=${sortOrder}&search=${this.search}&searchColumn=${this.searchColumn}&page=${page}&perPage=${itemsPerPage}&stationId=${this.selectedStation}&internet_connection_type=${this.internetConnectionType}`,
        )
        .then(response => {
          this.InternetPlans = response.data.data
          this.pagination.page = response.data.current_page
          this.pagination.pageCount = response.data.last_page
          this.pagination.itemsPerPage = response.data.per_page
          this.pagination.totalItems = response.data.total
        })
        .catch(error => {
          this.$toast.error(error.response.data.message)
        }).finally(() => {
          this.isInternetPlansLoading = false
          this.isInternetPlansRefreshing = false
        })
    }, 500),
    refreshInternetPlans() {
      this.isInternetPlansRefreshing = true
      this.showImportDialog = false
      this.getInternetPlans()
    },
    editInternetPlan(InternetPlan) {
      this.InternetPlan = InternetPlan
      this.showAddInternetPlanDialog = true
    },
    showDeleteInternetPlanDialog(id) {
      this.showConfirmDeleteDialog = true
      this.toDelete = id
    },
    addPrice(item) {
      this.InternetPlan = item
      this.showAddInternetPlanPriceDialog = true
    },
    toggleVisibility(internetPlan) {
      // Implement your logic here to toggle the visibility of the item
      // You may want to make an API call to update the `is_visible` field on the server
      internetPlan.is_visible = !internetPlan.is_visible
      axios.post(`/toggle-internet-plan-visibility/${internetPlan.id}`)
        .catch(error => {
        // Handle error, e.g., show an error message to the user
          console.error(error)
          internetPlan.is_visible = !internetPlan.is_visible
          this.$toast.error('An error occurred while toggling visibility!')
        })
    },
    deleteInternetPlan() {
      this.showConfirmDeleteDialog = false
      const index = this.InternetPlans.findIndex(
        item => item.id === this.toDelete,
      )
      this.InternetPlans.splice(index, 1)
      axios
        .delete(`internet-plans/${this.toDelete}`)
        .catch(error => {
          this.$toast.error(error.response.data.message)
          this.getInternetPlans()
        })
    },
    onInternetPlanAdded() {
      this.refreshInternetPlans()
    },
    onInternetPlanUpdated() {
      this.refreshInternetPlans()
    },
    onPageChange() {
      this.getInternetPlans()
    },
    onSearchFilterChange(filter) {
      this.searchColumn = filter
    },
    formatDataLimitName(dataLimitUnit, dataLimitValue) {
      const dataLimitUnitsEnum = {
        KB: 0,
        MB: 1,
        GB: 2,
        TB: 3,
      }

      let dataLimitName = ''

      switch (parseInt(dataLimitUnit, 10)) {
        case dataLimitUnitsEnum.KB:
          dataLimitName = `${dataLimitValue} KB`
          break
        case dataLimitUnitsEnum.MB:
          dataLimitName = `${dataLimitValue} MB`
          break
        case dataLimitUnitsEnum.GB:
          dataLimitName = `${dataLimitValue} GB`
          break
        case dataLimitUnitsEnum.TB:
          dataLimitName = `${dataLimitValue} TB`
          break
        default:
          dataLimitName = 'Invalid data limit unit'
      }

      return dataLimitName
    },
    getEnumKeyByValue(object, value) {
      return Object.keys(object).find(key => object[key] === value)
    },
    removeActionsHeader() {
      this.headers.pop()
    },
    isFreeTrialPlan(planName) {
      const lowerCasePlanName = planName.toLowerCase()

      return lowerCasePlanName.includes('free') || lowerCasePlanName.includes('trial')
    },
  },
}
</script>
